import React from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import Util from "../Util";

const LayoutDefault = ({ children }) => {
  return (
    <>
      <Header
        navPosition="right"
        className="reveal-from-bottom"
        hideSignin
        hideNav={Util.isMobile()}
        centered={Util.isMobile()}
      />
      <main className="site-content">{children}</main>
      <Footer />
    </>
  );
};

export default LayoutDefault;
