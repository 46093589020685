import Colors from './Colors';

const constants = {
  FONT_FAMILY: 'Roboto Condensed',
  PARAM_DELIMITER: '-',
  BLOCK_DELIMITER: '_',
  TIMERS: {
    TABATA: 'TABATA',
    EMOM: 'EMOM',
    FOR_TIME: 'FOR_TIME',
    AMRAP: 'AMRAP',
    REST: 'REST',
  },
  URI_PREFIX: 'https://wodblocks.com',
};

constants.TIMER_OBJECTS = {
  AMRAP: {
    key: constants.TIMERS.AMRAP,
    label: 'AMRAP',
    color: Colors.violet8,
    default: { seconds: 5 * 60 },
  },
  EMOM: {
    key: constants.TIMERS.EMOM,
    label: 'EMOM',
    color: Colors.pink8,
    default: { seconds: 60, rounds: 2 },
  },
  FOR_TIME: {
    key: constants.TIMERS.FOR_TIME,
    label: 'For Time',
    color: Colors.cyan9,
    default: { seconds: 5 * 60 },
  },
  TABATA: {
    key: constants.TIMERS.TABATA,
    label: 'TABATA',
    color: Colors.yellow9,
    default: { work: 20, rest: 10, rounds: 8 },
  },
  REST: {
    key: constants.TIMERS.REST,
    label: 'REST',
    color: Colors.gray6,
    default: { seconds: 2 * 60 },
  },
};

export default constants;
