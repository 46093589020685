import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Image from "../../elements/Image";

const Logo = ({ className, ...props }) => {
  const classes = classNames("brand", className);

  return (
    <div {...props} className={classes}>
      <h4 className="m-0">
        <Link
          to="/"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            src={require("./../../../assets/images/transparent.png")}
            alt="Open"
            width={32}
            height={32}
            style={{ height: 32, width: 32, margin: 0 }}
          />
          <span style={{ paddingLeft: 10 }}>Wod Blocks</span>
        </Link>
      </h4>
    </div>
  );
};

export default Logo;
