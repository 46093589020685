import React from "react";
import Image from "./Image";

const Screenshot = (props) => {
  const style = {
    ...props.style,
    borderWidth: props.small ? 10 : 20,
    borderColor: "#222",
    borderStyle: "solid",
    boxShadow: "0px 0px 50px rgba(0,0,0,0.2)",
  };

  const radius = props.small ? 30 : 55;

  if (props.topOnly) {
    style.borderTopLeftRadius = radius;
    style.borderTopRightRadius = radius;
    style.borderBottomWidth = 0;
  } else {
    style.borderRadius = radius;
  }

  return <Image {...props} style={style} />;
};

export default Screenshot;
