import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views
import Home from './views/Home';
import Build from './views/Build';
import Privacy from './views/Privacy';

const App = () => {
  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    document.body.classList.add('is-loaded');
    childRef.current.init();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute
            exact
            path="/privacy"
            component={Privacy}
            layout={LayoutDefault}
          />
          <AppRoute
            exact
            path="/w"
            component={Build}
            layout={LayoutDefault}
          />
        </Switch>
      )}
    />
  );
};

export default App;
