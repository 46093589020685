import React, { useState, useContext } from 'react';
import { Select, Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import constants from '../../constants';
import Util from '../../Util';
import { AppContext } from '../../context/AppContext';

const ExplantionWrapper = styled.div`
  display: flex;
  font-size: 25px;
  margin-bottom: 10px;
`;

const Text = styled.div`
  padding: 0px 2px 0px 0px;
  margin-left: ${(props) => (props.isSuffix ? 10 : 0)}px;
  margin-right: ${(props) => (props.isPrefix ? 10 : 0)}px;
`;

const SelectWrap = styled(Select)`
  border: ${(props) =>
    props.disabled ? 'none' : '1px solid rgba(0, 0, 0, 0.2)'};
  border-radius: 5px;

  .ant-select-selector {
    background-color: transparent !important;

    color: white !important;
    font-size: 25px;
    height: 40px !important;
    padding: ${(props) => (props.disabled ? '4px 0px' : '4px 11px')} !important;
  }
`;

const TextArea = styled(Input.TextArea)`
  background-color: transparent !important;
  border-radius: 5px;
  color: white;
  border: none;
  font-size: 22px;
  font-style: italic;
  margin-bottom: 10px;
  padding: 0px;

  &::placeholder {
    font-style: italic;
  }

  &:focus {
    border: none !important;
    box-shadow: none !important;
  }
`;

const Cell = styled.div`
  flex: 1;
  margin: 2.5px;
  text-align: center;
`;

const Remover = styled.div`
  color: rgba(0, 0, 0, 0.2);
  position: absolute;
  cursor: pointer;
  right: 13px;
  font-size: 35px;
  transition: all 0.2s ease-in-out;
  top: 5px;

  &:hover {
    color: rgba(0, 0, 0, 0.4);
  }
`;

const BlockWrapper = styled.div`
  background-color: ${(props) => props.color};
  opacity: ${(props) => (props.isDragging ? 0.7 : 1)};
  border: ${(props) =>
    props.isDragging ? '3px dotted white' : '3px solid transparent'};
  color: white;
  margin-bottom: 10px;
  padding: 5px 20px;
  border-radius: 15px;
  position: relative;
`;

const BlockName = styled.input`
  background: transparent;
  border-bottom: 1px dotted rgba(255, 255, 255, 0.3);
  border-top: none;
  border-left: none;
  border-right: none;
  font-size: 35px;
  font-style: italic;
  font-weight: bold;
  margin: 5px 5px 15px 0px;

  &::placeholder {
    color: white;
  }
  &:focus {
    outline: 0;
  }
`;

const Selector = ({ value, valueKey, blockKey, options, style = {} }) => {
  const { updateBlock, blocks } = useContext(AppContext);

  const isMobile = Util.isMobile();

  const block = blocks.find((b) => b.key === blockKey);

  return (
    <SelectWrap
      disabled={isMobile}
      showArrow={!isMobile}
      style={style}
      bordered={false}
      value={value}
      onChange={(s) => {
        updateBlock({
          ...block,
          [valueKey]: s,
        });
      }}
      dropdownMatchSelectWidth={false}
    >
      {options.map((o) => (
        <Select.Option key={o.value} value={o.value}>
          {o.label}
        </Select.Option>
      ))}
    </SelectWrap>
  );
};

const Description = ({ blockKey, defaultDescription }) => {
  const { updateBlock, blocks } = useContext(AppContext);
  const [description, setDescription] = useState(defaultDescription);
  const isMobile = Util.isMobile();

  if (isMobile && (!description || description.length === 0)) return null;

  const block = blocks.find((b) => b.key === blockKey);

  return (
    <TextArea
      autoSize
      disabled={isMobile}
      value={description}
      placeholder={isMobile ? null : 'Describe this block...'}
      style={{ resize: 'none', color: 'white' }}
      onChange={(e) => {
        const d = e.target.value;
        setDescription(d);
        updateBlock({
          ...block,
          description: d,
        });
      }}
    />
  );
};

const SingleTimer = ({ blockKey, options, prefix, suffix, post }) => {
  const { blocks } = useContext(AppContext);
  const { seconds, description } = blocks.find((b) => b.key === blockKey);

  return (
    <>
      <ExplantionWrapper>
        {prefix}
        <Selector
          value={seconds}
          valueKey="seconds"
          blockKey={blockKey}
          options={options}
        />
        {suffix}
      </ExplantionWrapper>
      <Description blockKey={blockKey} defaultDescription={description} />
    </>
  );
};

export const Amrap = ({ name, blockKey }) => {
  return (
    <SingleTimer
      blockKey={blockKey}
      options={Util.getMinutes({
        maxMinutes: 60,
        skipFirstMinutes: false,
        secondInterval: 60,
      })}
      prefix={<Text isPrefix>{name ? 'AMRAP ' : ' '}in</Text>}
    />
  );
};

export const ForTime = ({ blockKey }) => {
  return (
    <SingleTimer
      blockKey={blockKey}
      options={Util.getMinutes({
        maxMinutes: 60,
        skipFirstMinutes: true,
      })}
      suffix={<Text isSuffix>time cap</Text>}
    />
  );
};

export const Emom = ({ blockKey }) => {
  const { blocks } = useContext(AppContext);
  const block = blocks.find((b) => b.key === blockKey);

  const timeOptions = Util.getMinutes({
    maxMinutes: 60,
    secondInterval: 30,
  }).map((o) => ({ ...o, label: Util.clock(o.value) }));

  const roundOptions = timeOptions.map((o, i) => {
    const rounds = i + 2;
    const roundLabel = block.seconds === 60 ? '' : `(x${rounds})`;
    return {
      label: `${Util.formatTime(rounds * block.seconds)} ${roundLabel}`,
      value: rounds,
    };
  });

  return (
    <>
      <ExplantionWrapper>
        <Text isPrefix>Every</Text>
        <Selector
          value={block.seconds}
          valueKey="seconds"
          blockKey={blockKey}
          options={timeOptions}
        />
        <Text isPrefix isSuffix>
          for
        </Text>
        <Selector
          value={block.rounds}
          valueKey="rounds"
          blockKey={blockKey}
          options={roundOptions}
        />
      </ExplantionWrapper>
      <Description blockKey={blockKey} defaultDescription={block.description} />
    </>
  );
};

export const Tabata = ({ blockKey }) => {
  const { blocks } = useContext(AppContext);
  const block = blocks.find((b) => b.key === blockKey);

  const timeOptions = Util.getMinutes({
    maxMinutes: 60,
    secondInterval: 10,
  }).map((o) => ({ ...o, label: Util.clock(o.value) }));

  const roundOptions = [];
  for (let r = 1; r <= 20; r++) {
    roundOptions.push({
      label: r,
      value: r,
    });
  }

  return (
    <>
      <ExplantionWrapper>
        <Cell>
          <Text isPrefix>Work</Text>
          <Selector
            value={block.work}
            valueKey="work"
            blockKey={blockKey}
            options={timeOptions}
            style={{ width: '100%' }}
          />
        </Cell>
        <Cell>
          <Text isPrefix isSuffix>
            Rest
          </Text>
          <Selector
            value={block.rest}
            valueKey="rest"
            blockKey={blockKey}
            options={timeOptions}
            style={{ width: '100%' }}
          />
        </Cell>
        <Cell>
          <Text isPrefix isSuffix>
            Rounds
          </Text>
          <Selector
            value={block.rounds}
            valueKey="rounds"
            blockKey={blockKey}
            options={roundOptions}
            style={{ width: '100%' }}
          />
        </Cell>
      </ExplantionWrapper>
      <Description blockKey={blockKey} defaultDescription={block.description} />
    </>
  );
};

export const Rest = ({ blockKey }) => {
  return (
    <SingleTimer
      blockKey={blockKey}
      options={Util.getMinutes({
        maxMinutes: 60,
        skipFirstMinutes: false,
        secondInterval: 60,
      })}
      prefix={<Text isPrefix>For</Text>}
    />
  );
};

export const Block = ({ name, blockKey, type, isDragging }) => {
  const { blocks, removeBlock, updateBlock } = useContext(AppContext);
  const isMobile = Util.isMobile();
  const blockProps = { name, blockKey, type };
  const timer = constants.TIMER_OBJECTS[type];
  const block = blocks.find((b) => b.key === blockKey);

  let setter = null;
  if (type === constants.TIMER_OBJECTS.AMRAP.key) {
    setter = <Amrap {...blockProps} />;
  } else if (type === constants.TIMER_OBJECTS.FOR_TIME.key) {
    setter = <ForTime {...blockProps} />;
  } else if (type === constants.TIMER_OBJECTS.REST.key) {
    setter = <Rest {...blockProps} />;
  } else if (type === constants.TIMER_OBJECTS.EMOM.key) {
    setter = <Emom {...blockProps} />;
  } else if (type === constants.TIMER_OBJECTS.TABATA.key) {
    setter = <Tabata {...blockProps} />;
  }

  return (
    <BlockWrapper color={timer.color} isDragging={isDragging}>
      <BlockName
        defaultValue={name}
        placeholder={timer.label}
        onChange={(e) => {
          updateBlock({
            ...block,
            name: e.target.value,
          });
        }}
      />
      {!isMobile && (
        <Remover onClick={() => removeBlock({ key: blockKey })}>
          <CloseOutlined />
        </Remover>
      )}
      {setter}
    </BlockWrapper>
  );
};

export default { Block };
