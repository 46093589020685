import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import styled from 'styled-components';
import { Popover } from 'antd';
import QRCode from 'qrcode.react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Colors from '../Colors';
import Crypto from '../Crypto';
import constants from '../constants';
import Util from '../Util';
import Serializer from '../Serializer';

import AppContextProvider, { AppContext } from '../context/AppContext';

import { Block } from '../components/block/Blocks';
import AppDownloads from '../components/sections/AppDownloads';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 100px;
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;

  * {
    font-family: 'Roboto Condensed', sans-serif;
  }
`;

const Screen = styled.div`
  background: ${Colors.background};
  border: 20px solid #222;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
  border-radius: 55px;
  flex: 0 0 500px;
  padding: 20px;
  min-height: 1000px;
  z-index: 100;

  * {
    font-family: 'Roboto Condensed', sans-serif;
  }
`;

const SetupWrap = styled.div`
  * {
    font-family: 'Roboto Condensed', sans-serif;
  }

  width: 360px;
`;

const Buttons = styled.div`
  display: flex;
`;

const Button = styled.div`
  background-color: ${(props) => props.color || Colors.button};
  border-radius: 15px;
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: 30px;
  padding: 10px;
  flex: 1;
  text-align: center;
  margin-bottom: 5px;
`;

const Side = styled.div`
  padding-right: 40px;
  margin-top: 40px;
`;

const LinkHeader = styled.div`
  font-size: 30px;
  font-weight: bold;
`;

const Link = styled.a`
  font-size: 20px;
  color: ${Colors.blue5} !important;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
`;

const LinkToWorkout = styled.div`
  background-color: ${Colors.navy9};
  border-radius: 20px;
  color: white;
  margin-top: 40px;
  padding-top: 10px;
  overflow: hidden;
  text-align: center;
  margin-right: -60px;
`;

const QRWrapper = styled.div`
  background-color: white;
  margin-top: 20px;
  padding: 40px;
`;

const HeaderWrap = styled.div`
  padding: 50px 0px 30px 0px;
  text-align: center;
`;

const TotalTime = styled.div`
  border-bottom: 1px solid ${Colors.gray7};
  color: white;
  text-align: center;
  font-weight: bold;
  padding-bottom: 10px;
  font-size: 25px;
  margin-left: -20px;
  width: calc(100% + 40px);
`;

const WorkoutName = styled.input`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  border: none;
  color: white;
  font-size: 30px;
  margin: 10px 0px;
  padding: 5px 10px 3px 10px;
  text-align: center;
  width: 100%;

  &:focus {
    outline: 0;
  }
`;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Setup = ({ addBlock }) => {
  return (
    <SetupWrap>
      {Object.entries(constants.TIMER_OBJECTS).map(([k, v]) => (
        <Button key={k} color={v.color} onClick={() => addBlock(k)}>
          {v.label}
        </Button>
      ))}
    </SetupWrap>
  );
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const Inner = () => {
  const {
    blocks,
    clearBlocks,
    addBlock,
    setBlocks,
    workoutName,
    setWorkoutName,
  } = useContext(AppContext);
  const [addVisible, setAddVisible] = useState(false);
  const isMobile = Util.isMobile();
  const query = useQuery();
  const wod = query.get('wod');

  useEffect(() => {
    if (wod) {
      const decrypted = Crypto.decrypt(wod);
      const { name, blocks } = Serializer.deserializeBlocks(decrypted);
      setWorkoutName(name);
      setBlocks(blocks);
    }
  }, [setWorkoutName, setBlocks, wod]);

  const serialized = Serializer.serializeBlocks({ name: workoutName, blocks });
  const path = `/w?wod=${Crypto.encrypt(serialized)}`;
  const link = `${constants.URI_PREFIX}${path}`;

  useEffect(() => {
    window.history.pushState('wodblocks', 'Wod Blocks', path);
  });

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newBlocks = reorder(
      blocks,
      result.source.index,
      result.destination.index
    );

    setBlocks(newBlocks);
  };

  const meta = (
    <MetaTags>
      <meta name="title" content="Wod Blocks" />
      <meta name="description" content="The first full workout timer" />
    </MetaTags>
  );

  if (isMobile) {
    return (
      <div className="container">
        {meta}
        <HeaderWrap>
          <AppDownloads />
        </HeaderWrap>
        <MobileWrapper>
          {blocks.map((b, index) => (
            <Block key={index} blockKey={b.key} {...b} blocks={blocks} />
          ))}
        </MobileWrapper>
      </div>
    );
  }

  return (
    <div className="container">
      {meta}
      <Wrapper>
        <Side className="reveal-from-bottom" data-reveal-delay="600">
          <h1>Build a workout and send it to your phone</h1>
          <LinkToWorkout>
            <LinkHeader>Open this link on your phone</LinkHeader>
            <Link href={link}>{link}</Link>
            <br />
            <br />
            <LinkHeader>or just point the camera here</LinkHeader>
            <QRWrapper>
              <QRCode value={link} size={300} />
            </QRWrapper>
          </LinkToWorkout>
        </Side>
        <Screen>
          <TotalTime>
            Total Time: {Util.clock(Util.getBlocksDuration(blocks))}
          </TotalTime>
          <WorkoutName
            placeholder="Name this workout"
            style={{
              fontWeight:
                workoutName && workoutName.length > 0 ? 'bold' : 'normal',
            }}
            value={workoutName || ''}
            onChange={(e) => setWorkoutName(e.target.value)}
          />
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {blocks.map((b, index) => (
                    <Draggable key={b.key} draggableId={b.key} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Block
                            blockKey={b.key}
                            {...b}
                            blocks={blocks}
                            isDragging={snapshot.isDragging}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Buttons>
            {blocks.length > 0 && (
              <Button onClick={clearBlocks} style={{ marginRight: 10 }}>
                Clear
              </Button>
            )}
            <Popover
              visible={addVisible}
              onVisibleChange={setAddVisible}
              placement="bottom"
              trigger="click"
              content={
                <Setup
                  addBlock={(type) => {
                    addBlock({
                      type,
                      ...constants.TIMER_OBJECTS[type].default,
                    });
                    setAddVisible(false);
                  }}
                />
              }
            >
              <Button onClick={() => setAddVisible(true)}>Add Block</Button>
            </Popover>
          </Buttons>
        </Screen>
      </Wrapper>
    </div>
  );
};

const Build = () => {
  return (
    <AppContextProvider>
      <Inner />
    </AppContextProvider>
  );
};

export default Build;
