const createOption = (seconds) => {
  return {
    label: Util.formatTime(seconds),
    value: seconds,
  };
};

const Util = {
  isMobile: () => {
    let check = false;
    (function (a) {
      if (
        // eslint-disable-next-line
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        // eslint-disable-next-line
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  },
  getMobileOperatingSystem: () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return 'android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'ios';
    }

    return 'unknown';
  },
  getCurrentBlock: ({ blocks = [], elapsed }) => {
    let index = 0;
    let block = null;

    let acc = 0;
    while (index < blocks.length) {
      block = blocks[index];
      const duration = Util.getBlockDuration(block);

      if (acc + duration >= elapsed) {
        // block has not passed
        break;
      }

      // the block has passed, go on to next
      acc += duration;
      index++;
    }

    const result = {
      index,
      block,
      blockElapsed: elapsed - acc,
    };

    return {
      ...result,
      blockRemaining: block
        ? Util.getBlockDuration(block) - result.blockElapsed
        : 0,
    };
  },
  getBlockDuration: (block) => {
    if (!block) return 0;

    if (block.type === 'EMOM') {
      return block.seconds * block.rounds;
    } else if (block.type === 'TABATA') {
      return (block.work + block.rest) * block.rounds;
    } else {
      return block.seconds;
    }
  },
  getBlocksDuration: (blocks) => {
    return blocks.reduce((c, b) => {
      return c + Util.getBlockDuration(b);
    }, 0);
  },
  clock: (seconds) => {
    const m = Math.floor(seconds / 60);
    const s = seconds - m * 60;
    return [`${m}`.padStart(2, '0'), `${s}`.padStart(2, '0')].join(':');
  },
  playHalfwaySound: ({ roundDuration, secondsRemaining, playHalfway }) => {
    if (roundDuration < 30) return;

    if (roundDuration % 2 === 0 && secondsRemaining === roundDuration / 2) {
      // Even
      playHalfway();
    } else if (
      roundDuration % 2 === 1 &&
      secondsRemaining === (roundDuration + 1) / 2
    ) {
      // Odd
      setTimeout(playHalfway, 750);
    }
  },
  formatTime: (seconds) => {
    const m = Math.floor(seconds / 60);
    const s = seconds - m * 60;

    if (m === 0) {
      return `${s} seconds`;
    }

    let minuteDisplay = m;

    if (s !== 0 && m < 10) {
      minuteDisplay = `0${m}`;
    }

    const tokens = [minuteDisplay];

    if (s !== 0) {
      tokens.push(`${s}`.padStart(2, '0'));
    }

    let unit = m === 1 && s === 0 ? 'minute' : 'minutes';

    return `${tokens.join(':')} ${unit}`;
  },
  getMinutes: ({ maxMinutes, skipFirstMinutes, secondInterval }) => {
    const options = [];

    if (!skipFirstMinutes) {
      // First 5 minutes do 15 second intervals
      for (let m = 0; m <= 2; m++) {
        for (let s = 0; s < 60 / secondInterval; s++) {
          const seconds = m * 60 + s * secondInterval;
          if (seconds === 0) continue;
          options.push(createOption(seconds));
        }
      }
    }

    for (let m = 3; m <= maxMinutes; m++) {
      options.push(createOption(m * 60));
    }

    return options;
  },
  getRound: ({ roundDuration, blockElapsed }) => {
    let round = Math.floor((blockElapsed - 1) / roundDuration);
    round = round < 0 ? 0 : round;

    return {
      round,
      secondsRemaining: roundDuration - (blockElapsed - round * roundDuration),
    };
  },
};

export default Util;
