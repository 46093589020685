import React, { createContext, useState } from "react";
import Serializer from "../Serializer";

const AppContext = createContext({});

const AppContextProvider = ({ children }) => {
  const [workoutName, setWorkoutName] = useState(null);
  const [blocks, setBlocks] = useState([]);

  // Block functions
  const addBlock = newBlock => {
    setBlocks(
      blocks.concat({
        ...newBlock,
        key: Serializer.createKey(),
      }),
    );
  };

  const removeBlock = block => {
    setBlocks(blocks.filter(b => b.key !== block.key));
  };

  const clearBlocks = () => {
    setWorkoutName(null);
    setBlocks([]);
  };

  const updateBlock = block => {
    setBlocks(blocks.map(b => (b.key === block.key ? block : b)));
  };

  return (
    <AppContext.Provider
      value={{
        workoutName,
        setWorkoutName,
        blocks,
        addBlock,
        removeBlock,
        setBlocks,
        clearBlocks,
        updateBlock,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext };
export default AppContextProvider;
