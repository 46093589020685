import constants from './constants';
import JSON5 from 'json5';

const MAPPER = {
  seconds: 's',
  work: 'w',
  rest: 'r',
  rounds: 'x',
  description: 'd',
  name: 'n',
};

const Serializer = {
  stringifyJson: JSON5.stringify,
  parseJson: JSON5.parse,
  createKey: () => `${Math.floor(Math.random() * 1e10)}`,
  /**
   * Convert an array of blocks into an encoded string
   */
  serializeBlocks: ({ name, blocks }) => {
    const minifiedBlocks = blocks
      .map((b) => {
        const serializer = Serializer.minifiers[b.type];
        return serializer ? serializer(b) : null;
      })
      .filter((b) => !!b);

    const serialized = { b: minifiedBlocks };

    if (name) {
      serialized.n = name;
    }

    return Serializer.stringifyJson(serialized);
  },
  /**
   * Convert an encoded string into an array of blocks
   */
  deserializeBlocks: (str) => {
    try {
      const minified = Serializer.parseJson(str);

      const blocks = minified.b
        .map((m) => {
          const deminifier = Serializer.deminifiers[m.t];
          return deminifier ? deminifier(m) : null;
        })
        .filter((b) => !!b);

      return { name: minified.n, blocks };
    } catch (e) {
      // Invalid workout
      console.log('Failed to deserialize workout');
      console.log(e);
      return null;
    }
  },
  /**
   * Convert a single block object into an encoded string
   */
  minifyBlock: ({ keys, block }) => {
    const minified = {
      t: block.type[0].toLowerCase(),
    };

    let valid = true;

    keys.forEach((k) => {
      if (typeof block[k] === 'undefined') {
        valid = false;
      }

      minified[MAPPER[k]] = block[k];
    });

    if (!valid) return null;

    // Add name description
    ['name', 'description'].forEach((k) => {
      if (block[k]) {
        minified[MAPPER[k]] = block[k];
      }
    });

    return minified;
  },
  /**
   * Convert a single encoded block into the block object
   */
  deminifyBlock: ({ type, keys, minified }) => {
    // Invalid type
    if (!type) {
      return null;
    }

    const block = {
      key: Serializer.createKey(),
      type,
    };

    try {
      keys.forEach((k) => {
        block[k] = parseInt(minified[MAPPER[k]]);
      });
    } catch (e) {
      console.log('Failed to deserialize block');
      console.log(e);
      return null;
    }

    // Add name description
    ['name', 'description'].forEach((k) => {
      if (minified[MAPPER[k]]) {
        block[k] = minified[MAPPER[k]];
      }
    });

    return block;
  },
  minifiers: {
    [constants.TIMERS.AMRAP]: (block) =>
      Serializer.minifyBlock({
        block,
        keys: ['seconds'],
      }),
    [constants.TIMERS.EMOM]: (block) =>
      Serializer.minifyBlock({
        block,
        keys: ['seconds', 'rounds'],
      }),
    [constants.TIMERS.FOR_TIME]: (block) =>
      Serializer.minifyBlock({
        block,
        keys: ['seconds'],
      }),
    [constants.TIMERS.TABATA]: (block) =>
      Serializer.minifyBlock({
        block,
        keys: ['work', 'rest', 'rounds'],
      }),
    [constants.TIMERS.REST]: (block) =>
      Serializer.minifyBlock({
        block,
        keys: ['seconds'],
      }),
  },
  deminifiers: {
    a: (minified) =>
      Serializer.deminifyBlock({
        minified,
        type: constants.TIMERS.AMRAP,
        keys: ['seconds'],
      }),
    e: (minified) =>
      Serializer.deminifyBlock({
        minified,
        type: constants.TIMERS.EMOM,
        keys: ['seconds', 'rounds'],
      }),
    f: (minified) =>
      Serializer.deminifyBlock({
        minified,
        type: constants.TIMERS.FOR_TIME,
        keys: ['seconds'],
      }),
    t: (minified) =>
      Serializer.deminifyBlock({
        minified,
        type: constants.TIMERS.TABATA,
        keys: ['work', 'rest', 'rounds'],
      }),
    r: (minified) =>
      Serializer.deminifyBlock({
        minified,
        type: constants.TIMERS.REST,
        keys: ['seconds'],
      }),
  },
};

export default Serializer;
