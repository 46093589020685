import React from 'react';
import ButtonGroup from '../elements/ButtonGroup';
import Image from '../elements/Image';

const AppDownloads = () => {
  const android = (
    <a
      href="https://play.google.com/store/apps/details?id=com.wodblocks"
      style={{ margin: 10 }}
    >
      <Image
        src={require('./../../assets/images/google-play.png')}
        alt="Download on the Google Play Store"
        width={200}
        style={{ marginBottom: 10 }}
      />
    </a>
  );

  const ios = (
    <a
      href="https://apps.apple.com/us/app/wod-blocks/id1510490249"
      style={{ margin: 10 }}
    >
      <Image
        src={require('./../../assets/images/app-store.png')}
        alt="Download on the App Store"
        width={200}
        style={{ marginBottom: 10 }}
      />
    </a>
  );

  return (
    <ButtonGroup style={{ marginTop: 40 }}>
      {ios}
      {android}
    </ButtonGroup>
  );
};

export default AppDownloads;
