const Crypto = {
  encrypt: str => {
    try {
      return btoa(str);
    } catch (e) {
      console.log('crypto error encrypting', str, 'error', e);
      return null;
    }
  },
  decrypt: str => {
    try {
      return atob(str);
    } catch (e) {
      console.log('crypto error decrypting', str, 'error', e);
      return null;
    }
  },
};

export default Crypto;
